<template>
  <div class="py-8" v-if="slider[0]">
    <v-container>
      <v-row>
        <v-col
          ><div class="heading">
            WizFit Gallery
            <div class="hLine"></div></div
        ></v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col cols="12">
        <v-carousel
          :hide-delimiters="$vuetify.breakpoint.smAndUp"
          :show-arrows="arrowCondition && $vuetify.breakpoint.smAndUp"
        >
          <template v-slot:next="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              dark
              color="rgba(255, 255, 255, 0.8)"
              class="arrowButton px-0"
              style="background-color: rgba(255, 255, 255, 0.8)"
              text
            >
              <v-icon
                :size="$vuetify.breakpoint.smAndUp ? 40 : 20"
                color="#D30024"
                >mdi-chevron-right</v-icon
              >
            </v-btn>
          </template>

          <template v-slot:prev="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              dark
              color="rgba(255, 255, 255, 0.8)"
              class="arrowButton px-0"
              style="background-color: rgba(255, 255, 255, 0.8)"
              text
            >
              <v-icon
                :size="$vuetify.breakpoint.smAndUp ? 40 : 20"
                color="#D30024"
                >mdi-chevron-left</v-icon
              >
            </v-btn>
          </template>
          <template v-for="(item, index) in slider">
            <v-carousel-item
              v-if="(index + 1) % columns === 1 || columns === 1"
              :key="index"
              class="fill-height"
              style="height: 100%"
            >
              <v-row >
                <template v-for="(n, i) in columns">
                  <template v-if="+index + i < slider.length">
                    <v-col :key="i" cols="12" sm="4" class="px-3">
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <div
                          v-if="slider[index + i].type === 'image'"
                          class="galleryImageBox pt-15"
                          @click="
                            fullScreenImage(
                              slider[index + i].file_url,
                              slider[index + i].type
                            )
                          "
                        >
                          <img
                            :src="slider[index + i].file_url"
                            alt=""
                            class="galleryImage"
                            @mouseover="slider[index + i].upHere = true"
                            @mouseleave="slider[index + i].upHere = false"
                          />
                          <v-btn
                            dark
                            color="rgba(255, 255, 255, 0.2)"
                            style=""
                            class="shareButton text-capitalize"
                          >
                            <v-icon left color="white"
                              >mdi-share-variant-outline</v-icon
                            >
                            <span>share</span>
                          </v-btn>
                          <p v-if="slider[index + i].upHere" class="mediaTitle">
                            {{ slider[index + i].title }}
                          </p>
                        </div>
                        <div
                          v-else
                          class="galleryVideoBox"
                          @click="
                            fullScreenImage(
                              slider[index + i].file_url,
                              slider[index + i].type
                            )
                          "
                        >
                        
                          <div class="player_sizer">
                            <video
                              class="galleryVideo"
                              @mouseover="slider[index + i].upHere = true"
                              @mouseleave="slider[index + i].upHere = false"
                              preload="auto|none|metadata"
                              playsinline
                            >
                              <source :src="slider[index + i].file_url" />
                            </video>
                          </div>
                          <img
                            src="@/assets/play.png"
                            alt=""
                            class="playButtonImage"
                            @mouseover="slider[index + i].upHere = true"
                            @mouseleave="slider[index + i].upHere = false"
                          />
                        </div>
                        <!--  <div>{{slider[index + i]}}</div> -->
                      </v-row>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-carousel-item>
          </template>
        </v-carousel>
      </v-col>
    </v-row>
    <full-screen-image-viewer
      :imageViewer="imageViewer"
      v-if="imageViewer.flag"
    ></full-screen-image-viewer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import VueVideoThumbnail from "vue-video-thumbnail";
import { GET_GALLEY_ASSETS } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "WizfitGallery",
  components: {
    // VideoPlayer: () => import("@/components/VideoPlayer/VideoPlayer"),
    FullScreenImageViewer: () =>
      import("@/components/VideoPlayer/FullScreenImageViewer"),
    // VueVideoThumbnail,
  },
  data() {
    return {
      slider: [],
      imageViewer: {
        src: "",
        flag: false,
        type: "",
      },
      img: "",
      upHere: false,
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getCampaignDetail() {
      console.log(this.getCampaign.campaign_assests_list, "campaign assets");
      let asset_list = [];
      this.getCampaign.campaign_assests_list.forEach((element) => {
        let arr = {
          file: element.file,
          file_url: element.file_url,
          id: element.id,
          title: element.title,
          type: element.type,
          upHere: false,
        };
        asset_list.push(arr);
      });
      return asset_list;
    },
    getGallery() {
      let asset_list = [];
      if (this.getCampaign.type.type === "student") {
        asset_list = this.getCampaign.teacher_assests_list.concat(
          this.getCampaign.student_assests_list
        );
      } else if (this.getCampaign.type.type === "teacher") {
        asset_list = this.getCampaign.teacher_assests_list;
      }
      return asset_list;
    },
    arrowCondition() {
      if (this.slider.length > 3) {
        return true;
      } else if (this.$vuetify.breakpoint.xs && this.slider.length > 1) {
        return true;
      } else {
        return false;
      }
    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },
  },
  methods: {
    loadAssets() {
      this.slider = this.getCampaignDetail;
      if (this.getCampaign.type.type === "student") {
        this.slider = this.slider.concat(this.getGallery);
      } else if (this.getCampaign.type.type === "teacher") {
        this.slider = this.slider.concat(this.getGallery);
      }
    },
    fullScreenImage(url, type) {
      this.imageViewer.src = url;
      this.imageViewer.type = type;
      this.imageViewer.flag = true;
    },
    mapAssets(asset1, asset2) {
      if (this.getCampaign.type.type === "student") {
        this.slider = asset1;
      } else {
        this.slider = asset2;
      }
    },
    getGalleryAssets() {
      const successHandler = (res) => {
        // console.log(res.data, "........");
        let asset1 = [];
        let asset2 = [];
        if (this.getCampaign.type.type === "student") {
          asset1 = res.data.campaign_assests_list.concat(
            res.data.teacher_assests_list,
            res.data.student_assests_list,
            res.data.approved_student_assests_list
          );
        }else{
          asset2 = res.data.campaign_assests_list.concat(
            res.data.teacher_assests_list,
            res.data.approved_student_assests_list
          )
        }
        this.mapAssets(asset1, asset2);
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["campaign_id"] = this.getCampaign.campaign_detail.id;
      formData["student_id"] = this.getCampaign.student_detail.id;
      // if (!this.getCampaign.type) {
      //   // console.log("in campaign level");
      //   if (this.getCampaign.campaign_detail.campaign_type === "school") {
      //     // console.log(this.getCampaign.school_detail.id);
      //     formData["campaign_id"] = this.getCampaign.campaign_detail.id;
      //     formData["school_id"] = this.getCampaign.school_detail.id;
      //   } else {
      //     // console.log(this.getCampaign.district_detail.id);
      //     formData["campaign_id"] = this.getCampaign.campaign_detail.id;
      //     formData["district_id"] = this.getCampaign.district_detail.id;
      //   }
      // } else if (this.getCampaign.type.type === "school") {
      //   // console.log(this.getCampaign.school_detail.id);
      //   formData["campaign_id"] = this.getCampaign.campaign_detail.id;
      //   formData["school_id"] = this.getCampaign.school_detail.id;
      // } else if (this.getCampaign.type.type === "teacher") {
      //   // console.log("teacher");
      //   formData["teacher_id"] = this.getCampaign.teacher_detail.id;
      // } else {
      //   formData["student_id"] = this.getCampaign.student_detail.id;
      // }
      Axios.request_GET(
        GET_GALLEY_ASSETS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    // this.loadAssets();
    // console.log(this.getCampaign, "gallery campaign...");
    this.getGalleryAssets();
    // console.log(this.getStudentGallery, "student assets");
  },
};
</script>
<style scoped>
.heading {
  font-size: 25px;
  font-family: Roboto Slab;
  font-weight: 600;
  color: #2c1963;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.player_sizer {
  height: 100%;
  display: flex;
}
.arrowBox {
  min-height: 40px;
  min-width: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  border-radius: 50%;
}
.arrowButton.v-btn {
  width: 16px;
  height: 55px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
}
.galleryImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.galleryImageBox {
  cursor: pointer;
  height: 100%;
  padding-left: 2px;
}
.shareButton {
  position: relative;
  bottom: 15%;
  left: 75%;
  border-radius: 20px;
  display: none;
  height: 30px;
}
.mediaTitle {
  position: relative;
  bottom: 14%;
  left: 5%;
  color: white;
}
.mediaTitleV {
  position: relative;
  bottom: 30%;
  left: 7%;
  color: white;
}
.galleryVideoBox {
  width: 100%;
  height: 100%;
 padding-top: 60px;
  padding-left: 2px;
}
.galleryVideo {
  margin: auto;
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.playButtonImage {
  cursor: pointer;
  width: 70px;
  height: 70px;
  position:relative;
  left:45%;
  bottom:78%;
}

@media only screen and (max-width: 600px) {
  .galleryImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  /* .galleryImageBox {
    cursor: pointer;
    height: 50%;
    padding-left: 2px;
  }*/
  .galleryVideoBox {
    width: 100%;
    height: 100%;
    padding-left: 2px;
  } 
  .galleryVideo {
    margin: auto;
    cursor: pointer;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .playButtonImage {
    position: relative;
    bottom: 75%;
    left: 47%;
    height: 50px;
    width: 50px;
  }
  .arrowButton.v-btn {
    width: 10px;
    height: 50px;
  }

  .heading {
    font-size: 20px;
  }
  .hLine {
    width: 30px;
    height: 3px;
  }
}
</style>
